/**
* if set to device-width ie10 built-in scrollbar overflows content. Fix it as of here
* http://stackoverflow.com/questions/17045132/scrollbar-overlay-in-ie10-how-do-you-stop-that-might-be-bootstrap
*/
@-ms-viewport {
  width: auto;
}
/* line 10, ../sass/_general.scss */
html, body {
  height: 100%;
}

/* line 14, ../sass/_general.scss */
body {
  font-weight: 300;
  overflow-x: hidden;
}

/* line 19, ../sass/_general.scss */
th {
  font-weight: 600;
}