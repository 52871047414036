/***********************/
/*        Layout       */
/***********************/
/* line 6, ../sass/_base.scss */

/* line 22, ../sass/_base.scss */
.page-controls, .content-wrap, .loader-wrap {
  left: 240px;
  -webkit-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}
@media (min-width: 768px) {
  /* line 22, ../sass/_base.scss */
  .page-controls, .content-wrap, .loader-wrap {
    margin-left: 50px;
    left: 240px;
  }
}
/* line 31, ../sass/_base.scss */
.nav-collapsed .page-controls, .nav-collapsed .content-wrap, .nav-collapsed .loader-wrap {
  left: 0;
}
@media (min-width: 992px) {
  /* line 35, ../sass/_base.scss */
  .nav-static .page-controls, .nav-static .content-wrap, .nav-static .loader-wrap {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    left: 0;
    margin-left: 240px;
  }
}

/* line 44, ../sass/_base.scss */
.page-controls {
  -webkit-transition: left 0.3s ease-in-out, right 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out, right 0.3s ease-in-out;
  transition: left 0.3s ease-in-out, right 0.3s ease-in-out;
  right: -240px;
}
/* line 51, ../sass/_base.scss */
.nav-collapsed .page-controls {
  right: 0;
}
@media (min-width: 992px) {
  /* line 55, ../sass/_base.scss */
  .nav-static .page-controls {
      right: 0;
  }
}

/* line 62, ../sass/_base.scss */
.page-controls {
  position: absolute;
  z-index: 1;
}
@media (max-width: 767px) {
  /* line 62, ../sass/_base.scss */
  .page-controls {
      font-size: 16px;
  }
}
/* line 87, ../sass/_base.scss */
.page-controls .avatar {
  width: 30px;
  margin-top: -5px;
}
/* line 90, ../sass/_base.scss */
.page-controls .avatar.pull-left {
  margin-right: 5px;
}
/* line 93, ../sass/_base.scss */
.page-controls .avatar.pull-right {
  margin-left: 5px;
}

.content-wrapper {
  height: 100%;
}

/* line 123, ../sass/_base.scss */
.content-wrap {
  position: relative;
  z-index: 0;
  height: 100%;
}

/* line 140, ../sass/_base.scss */
.logo {
  margin: 10px 0 20px;
  height: 50px;
  width: 100%;
  line-height: 50px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  font-size: 18px;
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
/* line 151, ../sass/_base.scss */
.logo a {
  color: #fff;
  text-decoration: none;
}
/* line 156, ../sass/_base.scss */
.nav-collapsed .logo {
  width: 50px;
}
@media (min-width: 992px) {
  /* line 160, ../sass/_base.scss */
  .nav-static .logo {
      width: 100%;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
  }
}

@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  /* line 168, ../sass/_base.scss */
  .sidebar-nav {
      font-size: 16px;
  }
}
/* line 175, ../sass/_base.scss */
.sidebar-nav li a {
  display: block;
  color: #aaa;
  text-decoration: none;
}
/* line 180, ../sass/_base.scss */
.sidebar-nav li a .toggle {
  float: right;
  line-height: 18px;
  margin-right: 15px;
  -webkit-transition: -webkit-transform 0.35s ease;
  -moz-transition: -moz-transform 0.35s ease;
  -o-transition: -o-transform 0.35s ease;
  transition: transform 0.35s ease;
}
/* line 187, ../sass/_base.scss */
.sidebar-nav li a.collapsed .toggle {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
/* line 191, ../sass/_base.scss */
.sidebar-nav li a .label {
  float: right;
  line-height: 15px;
  margin-top: 11px;
  margin-right: 15px;
}
/* line 199, ../sass/_base.scss */
.sidebar-nav > li > a {
  position: relative;
  border-top: 1px solid #2d2d2d;
  padding-left: 50px;
  line-height: 35px;
}
/* line 205, ../sass/_base.scss */
.sidebar-nav > li > a:hover {
  background-color: #111;
}
@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  /* line 199, ../sass/_base.scss */
  .sidebar-nav > li > a {
      line-height: 44px;
  }
}
/* line 213, ../sass/_base.scss */
.sidebar-nav > li > a .icon {
  display: block;
  position: absolute;
  top: 3px;
  left: 11px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}
@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  /* line 213, ../sass/_base.scss */
  .sidebar-nav > li > a .icon {
      top: 8px;
  }
}
/* line 227, ../sass/_base.scss */
.sidebar-nav > li > a .icon .fas,
.sidebar-nav > li > a .icon .far,
.sidebar-nav > li > a .icon .glyphicon {
  font-size: 16px;
  color: rgba(170, 170, 170, 0.7);
}
/* line 232, ../sass/_base.scss */
.sidebar-nav > li > a .icon .glyphicon {
  left: -1px;
}
/* line 237, ../sass/_base.scss */
.sidebar-nav > li > a .icon .glyphicon-map-marker {
  top: 2px;
}
/* line 241, ../sass/_base.scss */
.sidebar-nav > li > a .icon .glyphicon-th {
  top: 1px;
  left: 0;
}
/* line 246, ../sass/_base.scss */
.sidebar-nav > li > a .icon .glyphicon-tree-conifer {
  left: 0;
}
/* line 250, ../sass/_base.scss */
.sidebar-nav > li > a .icon .fa-envelope {
  position: relative;
  top: -1px;
}
/* line 257, ../sass/_base.scss */
.sidebar-nav > li > a .toggle {
  line-height: 35px;
}
@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  /* line 257, ../sass/_base.scss */
  .sidebar-nav > li > a .toggle {
      line-height: 44px;
  }
}
/* line 266, ../sass/_base.scss */
.sidebar-nav > .open > a {
  background-color: #161616;
}
/* line 270, ../sass/_base.scss */
.sidebar-nav > li:last-child > a {
  border-bottom: 1px solid #2d2d2d;
}
/* line 274, ../sass/_base.scss */
.sidebar-nav > .active > a {
  color: #fe9900;
  background-color: #242424;
}
/* line 278, ../sass/_base.scss */
.sidebar-nav > .active > a .icon {
  border-radius: 50%;
  background-color: #fe9900;
}
/* line 282, ../sass/_base.scss */
.sidebar-nav > .active > a .icon .fas,
.sidebar-nav > .active > a .icon .far,
.sidebar-nav > .active > a .icon .glyphicon {
  color: #242424;
}
/* line 292, ../sass/_base.scss */
.sidebar-nav > li ul {
  padding: 0;
  font-size: 13px;
  background-color: #313131;
  list-style: none;
}
@media (min-width: 992px) and (min-height: 670px), (max-width: 767px) {
  /* line 292, ../sass/_base.scss */
  .sidebar-nav > li ul {
      font-size: 14px;
  }
}
/* line 302, ../sass/_base.scss */
.sidebar-nav > li ul > li {
  /* third submenu */
}
/* line 303, ../sass/_base.scss */
.sidebar-nav > li ul > li > a {
  padding: 5px 0 5px 40px;
}
/* line 305, ../sass/_base.scss */
.sidebar-nav > li ul > li > a:hover {
  background-color: #1e1e1e;
}
/* line 311, ../sass/_base.scss */
.sidebar-nav > li ul > li.active > a {
  font-weight: normal;
  color: #fff;
}
/* line 318, ../sass/_base.scss */
.sidebar-nav > li ul > li ul > li > a {
  padding-left: 55px;
}
/* line 327, ../sass/_base.scss */
.sidebar-nav > li > ul {
  /* simulate padding */
}
/* line 329, ../sass/_base.scss */
.sidebar-nav > li > ul:before {
  content: "";
  display: block;
  padding-top: 10px;
}
/* line 335, ../sass/_base.scss */
.sidebar-nav > li > ul:after {
  content: "";
  display: block;
  padding-bottom: 10px;
}

/* line 343, ../sass/_base.scss */
.sidebar-nav-title {
  margin: 35px 0 5px 11px;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
/* line 351, ../sass/_base.scss */
.sidebar-nav-title .action-link {
  color: #aaa;
  float: right;
  margin-right: 15px;
  margin-top: -1px;
}
/* line 357, ../sass/_base.scss */
.sidebar-nav-title .action-link .fas,
.sidebar-nav-title .action-link .far,
.sidebar-nav-title .action-link .glyphicon {
  font-size: 9px;
}
/* line 362, ../sass/_base.scss */
.nav-collapsed .sidebar-nav-title {
  opacity: 0;
}
@media (min-width: 992px) {
  /* line 366, ../sass/_base.scss */
  .nav-static .sidebar-nav-title {
      opacity: 1;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
  }
}

/* line 374, ../sass/_base.scss */
.sidebar-status {
  position: relative;
  margin: 10px 0 20px;
  padding: 0 15px 0 11px;
  /* fix notifications dropdown when there is no enough vertical space*/
}
/* line 379, ../sass/_base.scss */
.sidebar-status > a {
  display: block;
  line-height: 40px;
  color: #eeeeee;
  text-decoration: none;
}
/* line 386, ../sass/_base.scss */
.sidebar-status .dropdown-menu {
  position: fixed;
  top: 60px;
  margin: 0 20px;
  width: 320px;
  color: #555555;
}
@media (max-width: 380px) {
  /* line 386, ../sass/_base.scss */
  .sidebar-status .dropdown-menu {
      width: 100%;
      margin: 0;
  }
}
@media (max-height: 500px) {
  /* line 401, ../sass/_base.scss */
  .sidebar-status .dropdown-menu {
      bottom: 20px;
  }
  /* line 406, ../sass/_base.scss */
  .sidebar-status .notifications .panel-heading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
  }
  /* line 413, ../sass/_base.scss */
  .sidebar-status .notifications .list-group {
      position: absolute;
      top: 85px;
      left: 0;
      right: 0;
      bottom: 43px;
      height: auto;
  }
  /* line 422, ../sass/_base.scss */
  .sidebar-status .notifications .panel-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
  }
}

/* line 432, ../sass/_base.scss */
.sidebar-labels {
  list-style: none;
  padding: 11px;
  padding-right: 15px;
  font-size: 13px;
}
/* line 438, ../sass/_base.scss */
.sidebar-labels > li > a {
  color: #eeeeee;
  text-decoration: none;
  font-weight: normal;
}
/* line 443, ../sass/_base.scss */
.sidebar-labels > li > a .circle,
.sidebar-labels > li > a .circle-o {
  position: relative;
  top: -2px;
  font-size: 9px;
  height: 12px;
  width: 12px;
  line-height: 12px;
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}
/* line 454, ../sass/_base.scss */
.sidebar-labels > li > a .fa-circle {
  font-size: 11px;
  vertical-align: 1px;
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}
/* line 460, ../sass/_base.scss */
.sidebar-labels > li > a .circle-o {
  top: 1px;
  left: 1px;
}
/* line 465, ../sass/_base.scss */
.sidebar-labels > li > a .label-name {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
/* line 471, ../sass/_base.scss */
.nav-collapsed .sidebar-labels > li > a .circle,
.nav-collapsed .sidebar-labels > li > a .circle-o,
.nav-collapsed .sidebar-labels > li > a .fa-circle {
  margin-left: 8px;
}
/* line 477, ../sass/_base.scss */
.nav-collapsed .sidebar-labels > li > a .label-name {
  opacity: 0;
}
@media (min-width: 992px) {
  /* line 484, ../sass/_base.scss */
  .nav-static .sidebar-labels > li > a .circle,
  .nav-static .sidebar-labels > li > a .circle-o,
  .nav-static .sidebar-labels > li > a .fa-circle {
      margin-left: 0;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
  }
  /* line 490, ../sass/_base.scss */
  .nav-static .sidebar-labels > li > a .label-name {
      opacity: 1;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
  }
}
/* line 498, ../sass/_base.scss */
.sidebar-labels > li + li {
  margin-top: 10px;
}

/* line 504, ../sass/_base.scss */
.sidebar-alerts {
  font-size: 13px;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
/* line 509, ../sass/_base.scss */
.nav-collapsed .sidebar-alerts {
  opacity: 0;
}
@media (min-width: 992px) {
  /* line 513, ../sass/_base.scss */
  .nav-static .sidebar-alerts {
      opacity: 1;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
  }
}
/* line 519, ../sass/_base.scss */
.sidebar-alerts .alert {
  margin-bottom: 0;
  padding: 10px 11px;
  padding-right: 15px;
}
/* line 524, ../sass/_base.scss */
.sidebar-alerts .alert .close {
  font-size: 16px;
  text-shadow: none;
  opacity: 1;
  color: #aaa;
}
/* line 532, ../sass/_base.scss */
.sidebar-alerts .progress {
  background-color: #3e3e3e;
}

/* line 537, ../sass/_base.scss */
.chat-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -254px;
  width: 254px;
  background-color: #242424;
  -webkit-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}

/* body tag is given this class */
/* line 548, ../sass/_base.scss */
.chat-sidebar-container {
  position: relative;
}
/* line 550, ../sass/_base.scss */
.chat-sidebar-container, .chat-sidebar-container .sidebar {
  left: 0;
  -webkit-transition: left 0.3s ease-in-out;
  -o-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}
/* line 555, ../sass/_base.scss */
.chat-sidebar-container.chat-sidebar-opened, .chat-sidebar-container.chat-sidebar-opened .sidebar {
  left: -254px;
}
/* line 559, ../sass/_base.scss */
.chat-sidebar-container.chat-sidebar-opened .chat-sidebar {
  right: 0;
}

/* line 565, ../sass/_base.scss */
.chat-sidebar-header {
  position: absolute;
  top: 0;
  padding: 10px;
  z-index: 3;
  background-color: #242424;
}

/* line 573, ../sass/_base.scss */
.chat-sidebar-content {
  color: #aaa;
}

/* line 578, ../sass/_base.scss */
.chat-sidebar-contacts {
  padding: 20px;
  left: -100%;
  -webkit-transition: left 0.4s ease-in-out;
  -o-transition: left 0.4s ease-in-out;
  transition: left 0.4s ease-in-out;
}
/* line 582, ../sass/_base.scss */
.chat-sidebar-contacts.open {
  left: 0;
}
/* line 585, ../sass/_base.scss */
.chat-sidebar-contacts .sidebar-nav-title {
  margin-left: 10px;
  margin-right: 10px;
}
/* line 589, ../sass/_base.scss */
.chat-sidebar-contacts .sidebar-nav-title:first-child {
  margin-top: 0;
}
/* line 593, ../sass/_base.scss */
.nav-collapsed .chat-sidebar-contacts .sidebar-nav-title {
  opacity: 1;
}

/* line 599, ../sass/_base.scss */
.chat-sidebar-chat {
  right: -100%;
  padding-bottom: 54px;
  /*footer height*/
  background-color: #242424;
  -webkit-transition: right 0.2s ease-in-out;
  -o-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out;
}
/* line 604, ../sass/_base.scss */
.chat-sidebar-chat.open {
  right: 0;
}
/* line 608, ../sass/_base.scss */
.chat-sidebar-chat .title {
  margin-bottom: 0;
}
/* line 610, ../sass/_base.scss */
.chat-sidebar-chat .title > a {
  display: block;
  padding: 10px 16px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #eeeeee;
  text-decoration: none;
}
/* line 619, ../sass/_base.scss */
.chat-sidebar-chat .message-list {
  list-style: none;
  padding-left: 0;
  padding-top: 10px;
}
/* line 625, ../sass/_base.scss */
.chat-sidebar-chat .message {
  padding: 10px 20px;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.chat-sidebar-chat .message:before, .chat-sidebar-chat .message:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.chat-sidebar-chat .message:after {
  clear: both;
}
/* line 628, ../sass/_base.scss */
.chat-sidebar-chat .message > .thumb-sm {
  float: left;
}
/* line 632, ../sass/_base.scss */
.chat-sidebar-chat .message.from-me > .thumb-sm {
  float: right;
}

/* line 640, ../sass/_base.scss */
.chat-sidebar-chat .message-body {
  position: relative;
  margin-left: 50px;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  background-color: #eeeeee;
  color: #555555;
  border-radius: 4px;
}
/* line 650, ../sass/_base.scss */
.chat-sidebar-chat .message-body:before {
  right: 100%;
  top: 8px;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #eeeeee;
}
/* line 662, ../sass/_base.scss */
.chat-sidebar-chat .message.from-me .message-body {
  margin-left: 0;
  margin-right: 50px;
  background-color: #f0b518;
  color: #343434;
}
/* line 667, ../sass/_base.scss */
.chat-sidebar-chat .message.from-me .message-body:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #f0b518;
}

/* line 676, ../sass/_base.scss */
.chat-sidebar-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  padding-top: 100px;
}

/* line 684, ../sass/_base.scss */
.chat-sidebar-title {
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
}

/* line 692, ../sass/_base.scss */
.chat-sidebar-user-group {
  margin-top: 10px;
}
/* line 694, ../sass/_base.scss */
.chat-sidebar-user-group .list-group-item {
  background-color: transparent;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 0;
  padding: 10px 20px;
  border: 0;
  color: #eeeeee;
  border-radius: 0;
}
/* line 705, ../sass/_base.scss */
.chat-sidebar-user-group .list-group-item.active, .chat-sidebar-user-group .list-group-item.active:focus, .chat-sidebar-user-group .list-group-item.active:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #f0b518;
}
/* line 712, ../sass/_base.scss */
.chat-sidebar-user-group .list-group-item.active .badge {
  background-color: #dd5826;
  color: #fff;
}
/* line 717, ../sass/_base.scss */
.chat-sidebar-user-group .list-group-item.active .message-sender {
  font-weight: 600;
}
/* line 722, ../sass/_base.scss */
.chat-sidebar-user-group .list-group-item .circle-o {
  margin-top: 13px;
  font-size: 8px;
}
/* line 727, ../sass/_base.scss */
.chat-sidebar-user-group .list-group-item .fa-circle {
  font-size: 11px;
  line-height: 37px;
}
/* line 732, ../sass/_base.scss */
.chat-sidebar-user-group .list-group-item .badge {
  margin-top: 9px;
  margin-right: 6px;
  padding: 3px 5px;
}
/* line 739, ../sass/_base.scss */
.chat-sidebar-user-group .thumb .status,
.chat-sidebar-user-group .thumb-sm .status {
  border-color: #242424;
}
/* line 744, ../sass/_base.scss */
.chat-sidebar-user-group a.list-group-item:hover {
  color: #eeeeee;
  background-color: rgba(0, 0, 0, 0.15);
}
/* line 749, ../sass/_base.scss */
.chat-sidebar-user-group .message-preview {
  margin: 0;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 85%;
  color: #999999;
}
/* line 759, ../sass/_base.scss */
.chat-sidebar-user-group .message-sender {
  margin: 0 0 5px;
}

/* line 764, ../sass/_base.scss */
.chat-sidebar-footer {
  position: absolute;
  bottom: 0;
  right: -100%;
  width: 100%;
  margin-bottom: 0;
  padding: 10px;
  background-color: #3a3a3a;
  -webkit-transition: right 0.2s ease-in-out;
  -o-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out;
}
/* line 774, ../sass/_base.scss */
.chat-sidebar-footer.open {
  right: 0;
}

/* line 779, ../sass/_base.scss */
.chat-notification {
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 20;
  margin-top: 3px;
  padding: 5px 0;
  cursor: pointer;
}
/* line 788, ../sass/_base.scss */
.chat-notification:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 18px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #343434;
}

/* line 801, ../sass/_base.scss */
.chat-notification-inner {
  min-width: 120px;
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
  text-decoration: none;
  background-color: #343434;
  color: #fff;
}
/* line 810, ../sass/_base.scss */
.chat-notification-inner .title {
  margin: 0;
  font-weight: 600;
  line-height: 28px;
}
/* line 815, ../sass/_base.scss */
.chat-notification-inner .title .thumb-xs {
  vertical-align: -9px;
  margin-right: 5px;
}
/* line 821, ../sass/_base.scss */
.chat-notification-inner .text {
  margin-top: 5px;
  margin-bottom: 0;
  color: #999999;
}

/* line 828, ../sass/_base.scss */
.chat-notification-sing {
  position: absolute;
  top: 15px;
  left: 11px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #dd5826;
}

/* line 839, ../sass/_base.scss */
.page-title {
  margin: 0 0 20px;
}

@media (min-width: 768px) {
  /* line 843, ../sass/_base.scss */
  .notifications {
      width: 333px;
  }
}
/* line 847, ../sass/_base.scss */
.notifications .list-group {
  height: 320px;
  overflow-y: scroll;
}
/* line 851, ../sass/_base.scss */
.notifications .list-group-item {
  -webkit-transition: background-color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
}
/* line 853, ../sass/_base.scss */
.notifications .list-group-item:hover {
  background-color: #f5f5f5;
}
/* line 855, ../sass/_base.scss */
.notifications .list-group-item:hover .progress {
  background-color: #fff;
}
/* line 859, ../sass/_base.scss */
.notifications .list-group-item .progress {
  -webkit-transition: background-color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
}
/* line 864, ../sass/_base.scss */
.notifications .btn-notifications-reload {
  color: #666;
}

/***********************/
/*        Loader       */
/***********************/
/* line 873, ../sass/_base.scss */
.loader-wrap {
  position: fixed;
  z-index: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: #eee;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, left 0.3s ease-in-out, opacity 0.2s ease-out, background-color 0.2s ease-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, left 0.3s ease-in-out, opacity 0.2s ease-out, background-color 0.2s ease-out;
  -o-transition: -o-transform 0.3s ease-in-out, left 0.3s ease-in-out, opacity 0.2s ease-out, background-color 0.2s ease-out;
  transition: transform 0.3s ease-in-out, left 0.3s ease-in-out, opacity 0.2s ease-out, background-color 0.2s ease-out;
}
/* line 884, ../sass/_base.scss */
.loader-wrap.hiding {
  opacity: 0;
  background-color: transparent;
}
/* line 889, ../sass/_base.scss */
.loader-wrap .fas,
.loader-wrap .far {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 30px;
  margin-top: -15px;
  margin-left: -10px;
  font-size: 20px;
  vertical-align: middle;
}
@media (min-width: 992px) {
  /* line 900, ../sass/_base.scss */
  .nav-static .loader-wrap {
      -webkit-transition: opacity 0.2s ease-out, background-color 0.2s ease-out;
      -o-transition: opacity 0.2s ease-out, background-color 0.2s ease-out;
      transition: opacity 0.2s ease-out, background-color 0.2s ease-out;
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
      margin-left: 220px;
  }
}

/***********************/
/*       Widget        */
/***********************/
/* line 913, ../sass/_base.scss */
.widget {
  position: relative;
  margin-bottom: 30px;
  padding: 15px 20px;
  background: #fff;
  border-radius: 3px;
}
/* line 920, ../sass/_base.scss */
.widget > header {
  margin: -15px -20px;
  padding: 15px 20px;
}
/* line 923, ../sass/_base.scss */
.widget > header h1, .widget > header h2, .widget > header h3, .widget > header h4, .widget > header h5, .widget > header h6 {
  margin: 0;
}
/* line 927, ../sass/_base.scss */
.widget > header + .widget-body,
.widget > header + .widget-body.no-padding {
  margin-top: 15px;
}
/* line 933, ../sass/_base.scss */
.widget > .widget-body.no-padding {
  margin: -15px -20px;
}
/* line 936, ../sass/_base.scss */
.widget > .widget-body.no-padding + footer {
  margin-top: 15px;
}
/* line 941, ../sass/_base.scss */
.widget > footer {
  margin: 0 -20px -15px;
  padding: 15px 20px;
}
/* line 946, ../sass/_base.scss */
.widget:hover .widget-controls-hover {
  opacity: 1;
}
/* line 950, ../sass/_base.scss */
.widget .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/* line 957, ../sass/_base.scss */
.widget .loader .spinner {
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -10px;
  font-size: 20px;
  text-align: center;
}
/* line 967, ../sass/_base.scss */
.widget .widget-table-overflow {
  margin: 0 -20px -15px;
}

/* line 972, ../sass/_base.scss */
.widget-controls {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 14px;
  font-size: 12px;
}
/* line 980, ../sass/_base.scss */
.widget-controls.left {
  left: 0;
  right: auto;
}
/* line 985, ../sass/_base.scss */
.widget-controls.widget-controls-hover {
  opacity: 0;
  -webkit-transition: opacity 0.15s;
  -o-transition: opacity 0.15s;
  transition: opacity 0.15s;
}
/* line 992, ../sass/_base.scss */
.widget-controls > a {
  padding: 1px 4px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.4);
  -webkit-transition: color 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}
/* line 998, ../sass/_base.scss */
.widget-controls > a:hover {
  color: rgba(0, 0, 0, 0.1);
}
/* line 1002, ../sass/_base.scss */
.widget-controls > a > .fas,
.widget-controls > a > .far {
  vertical-align: middle;
}
/* line 1006, ../sass/_base.scss */
.widget-controls > a > .glyphicon {
  vertical-align: -1px;
}
/* line 1011, ../sass/_base.scss */
.widget-controls > .btn-group {
  vertical-align: baseline;
}
/* line 1015, ../sass/_base.scss */
.widget-controls .fa-refresh {
  position: relative;
  top: -1px;
}

/* line 1021, ../sass/_base.scss */
.widget-icon {
  opacity: .5;
  font-size: 42px;
}

/* line 1026, ../sass/_base.scss */
.widget-image {
  position: relative;
  overflow: hidden;
  margin: -15px -20px;
  border-radius: 4px;
}
/* line 1032, ../sass/_base.scss */
.widget-image > img {
  max-width: 100%;
  border-radius: 4px 4px 0 0;
  -webkit-transition: -webkit-transform 0.15s ease;
  -moz-transition: -moz-transform 0.15s ease;
  -o-transition: -o-transform 0.15s ease;
  transition: transform 0.15s ease;
}
/* line 1038, ../sass/_base.scss */
.widget-image:hover > img {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
/* line 1042, ../sass/_base.scss */
.widget-image .title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
}
/* line 1049, ../sass/_base.scss */
.widget-image .info {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}

/* line 1057, ../sass/_base.scss */
.widget-footer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* line 1063, ../sass/_base.scss */
.widget-sm {
  height: 230px;
}

/* line 1067, ../sass/_base.scss */
.widget-md {
  height: 373px;
}

/* line 1071, ../sass/_base.scss */
.windget-padding-md {
  padding: 15px 20px;
}

/* line 1075, ../sass/_base.scss */
.windget-padding-lg {
  padding: 30px 40px;
}

/* line 1079, ../sass/_base.scss */
.widget-body-container {
  position: relative;
  height: 100%;
}

/* line 1084, ../sass/_base.scss */
.widget-top-overflow,
.widget-middle-overflow {
  position: relative;
  margin: 0 -20px;
}
/* line 1089, ../sass/_base.scss */
.widget-top-overflow > img,
.widget-middle-overflow > img {
  max-width: 100%;
}

/* line 1094, ../sass/_base.scss */
.widget-top-overflow {
  margin-top: -15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
/* line 1099, ../sass/_base.scss */
.widget-top-overflow > img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/***********************************/
/**         Widgets Grid          **/
/***********************************/
/* line 1109, ../sass/_base.scss */
.widget-container {
  min-height: 30px;
}
/* line 1112, ../sass/_base.scss */
.widget-container .widget:not(.fullscreened) > header {
  cursor: move;
}

/* line 1117, ../sass/_base.scss */
.widget-placeholder {
  background: rgba(255, 255, 255, 0.4);
  border: 1px dashed #999999;
  margin: -1px -1px 29px -1px;
}

/***********************/
/*        Forms        */
/***********************/
/* line 1127, ../sass/_base.scss */
.form-actions {
  margin: 20px 0 0;
  padding: 20px;
  background-color: #f5f5f5;
}
/* line 1132, ../sass/_base.scss */
.form-horizontal .form-actions .row {
  margin-left: -35px;
  margin-right: -35px;
}

/* line 1138, ../sass/_base.scss */
input[type=search], input.search {
  background-color: #fff;

  // ASSET NOT PRESENT??? 
  // background: url(../img/search.png) 5px 60% no-repeat #fff;
}
/* line 1139, ../sass/_base.scss */
input[type=search], .input-group-lg > input[type=search], .input-group-sm > input[type=search], input.search, .input-group-lg > input.search, .input-group-sm > input.search {
  padding-left: 26px;
}

/***********************/
/*       Buttons       */
/***********************/
/* line 1150, ../sass/_base.scss */
.btn-inverse {
  color: #fff;
  background-color: #555555;
  border-color: #3c3c3c;
}
/* line 11, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_buttons.scss */
.btn-inverse:hover, .btn-inverse:focus, .btn-inverse.focus, .btn-inverse:active, .btn-inverse.active, .open > .btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #3c3c3c;
  border-color: #1d1d1d;
}
/* line 21, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_buttons.scss */
.btn-inverse:active, .btn-inverse.active, .open > .btn-inverse.dropdown-toggle {
  background-image: none;
}
/* line 29, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_buttons.scss */
.btn-inverse.disabled, .btn-inverse.disabled:hover, .btn-inverse.disabled:focus, .btn-inverse.disabled.focus, .btn-inverse.disabled:active, .btn-inverse.disabled.active, .btn-inverse[disabled], .btn-inverse[disabled]:hover, .btn-inverse[disabled]:focus, .btn-inverse[disabled].focus, .btn-inverse[disabled]:active, .btn-inverse[disabled].active, fieldset[disabled] .btn-inverse, fieldset[disabled] .btn-inverse:hover, fieldset[disabled] .btn-inverse:focus, fieldset[disabled] .btn-inverse.focus, fieldset[disabled] .btn-inverse:active, fieldset[disabled] .btn-inverse.active {
  background-color: #555555;
  border-color: #3c3c3c;
}
/* line 40, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_buttons.scss */
.btn-inverse .badge {
  color: #555555;
  background-color: #fff;
}

/***********************/
/*     Content Map     */
/***********************/
/* line 1158, ../sass/_base.scss */
.content-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/* line 1164, ../sass/_base.scss */
.content-map + .page-title {
  position: relative;
  z-index: 2;
}

/* line 1170, ../sass/_base.scss */
.content-map-controls {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  margin-top: 90px;
  margin-right: 40px;
}

.toplogo {
  width: 200px;
  margin-left: 25px;
  margin-top: 5px;
}

.panel__closing-icon {
  position: absolute;
  right: 0;
}

.scroll-x {
  overflow-x: scroll;
}

.grid-view .pager ul.yiiPager {
  li {
    a {
      padding: 8px;
      border-radius: 4px;
      border: none;
    }
  }
}
