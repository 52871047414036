/***********************/
/*                     */
/*   Custom Widgets    */
/*                     */
/***********************/
/***********************/
/*     Chart Simple    */
/***********************/
/* line 14, ../sass/_widgets.scss */
.widget-chart-simple .widget-body {
  padding-top: 10px;
}
/* line 17, ../sass/_widgets.scss */
.widget-chart-simple .chart-value {
  font-size: 24px;
}
/* line 21, ../sass/_widgets.scss */
.widget-chart-simple .chart {
  margin-top: 20px;
}

/* line 26, ../sass/_widgets.scss */
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/***********************/
/*    Chart Changes    */
/***********************/
/* line 35, ../sass/_widgets.scss */
.widget-chart-changes .chart {
  margin: -15px -20px 0;
  padding: 15px 0 0;
}
/* line 40, ../sass/_widgets.scss */
.widget-chart-changes .chart-title {
  margin: 20px 0 0;
}
/* line 44, ../sass/_widgets.scss */
.widget-chart-changes .chart-value, .widget-chart-changes .chart-value-change {
  padding: 0 20px;
}
/* line 48, ../sass/_widgets.scss */
.widget-chart-changes .chart-value {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 21px;
  line-height: 1;
  color: #fff;
}
/* line 56, ../sass/_widgets.scss */
.widget-chart-changes .chart-value-change {
  color: rgba(255, 255, 255, 0.7);
  font-size: 85%;
}

/***********************/
/*  Chart Stats Simple */
/***********************/
/* line 68, ../sass/_widgets.scss */
.widget-chart-stats-simple > header h1, .widget-chart-stats-simple > header h2, .widget-chart-stats-simple > header h3, .widget-chart-stats-simple > header h4, .widget-chart-stats-simple > header h5, .widget-chart-stats-simple > header h6 {
  margin-bottom: 5px;
}
/* line 73, ../sass/_widgets.scss */
.widget-chart-stats-simple .chart {
  height: 200px;
  margin: 10px -20px -15px;
}
/* line 76, ../sass/_widgets.scss */
.widget-chart-stats-simple .chart > .chart-inner {
  height: 100%;
}

/***********************/
/*      Big Chart      */
/***********************/
/* line 87, ../sass/_widgets.scss */
.widget-big-chart .chart {
  height: 200px;
}

/***********************/
/*  Part:Chart Stats   */
/***********************/
/* line 96, ../sass/_widgets.scss */
.chart-stats {
  border-top: 1px solid #eeeeee;
}

/***********************/
/* Part:Progress Stats */
/***********************/
/* line 105, ../sass/_widgets.scss */
.progress-stats .name {
  margin-bottom: 2px;
}
/* line 108, ../sass/_widgets.scss */
.progress-stats .description {
  margin-bottom: 2px;
}
/* line 111, ../sass/_widgets.scss */
.progress-stats .status {
  margin-top: 15px;
}
@media (max-width: 1199px) {
  /* line 116, ../sass/_widgets.scss */
  .progress-stats .description + .progress {
      margin-bottom: 0;
  }
}

/***********************/
/*    Part:Stats Row   */
/***********************/
/* line 126, ../sass/_widgets.scss */
.stats-row {
  margin-bottom: 15px;
}

/* line 130, ../sass/_widgets.scss */
.stat-item {
  display: inline-block;
  padding-right: 15px;
}
/* line 134, ../sass/_widgets.scss */
.stat-item + .stat-item {
  padding-left: 15px;
  border-left: 1px solid #eeeeee;
}
/* line 138, ../sass/_widgets.scss */
.stat-item .name {
  margin-bottom: 2px;
}
/* line 141, ../sass/_widgets.scss */
.stat-item .value {
  font-weight: bold;
}
/* line 145, ../sass/_widgets.scss */
.stat-item.stat-item-mini-chart {
  position: relative;
  top: -12px;
  padding-left: 0;
  border-left: none;
}

/***********************/
/*  Part:Map Controls  */
/***********************/
/* line 158, ../sass/_widgets.scss */
.map-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #bbb;
  background-color: #ddd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
/* line 168, ../sass/_widgets.scss */
.map-controls > li > a {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  color: #555555;
}
/* line 174, ../sass/_widgets.scss */
.map-controls > li > a:hover {
  background-color: #eeeeee;
  color: #555555;
}
/* line 181, ../sass/_widgets.scss */
.map-controls > li.active > a, .map-controls > li.active > a:hover {
  background-color: #fff;
  color: #555555;
  font-weight: 700;
}
/* line 188, ../sass/_widgets.scss */
.map-controls > li:first-child > a {
  border-bottom-left-radius: 4px;
}
/* line 192, ../sass/_widgets.scss */
.map-controls > li:last-child > a {
  border-bottom-right-radius: 4px;
}

/***********************************/
/**          NEWS LIST           **/
/**********************************/
/* line 201, ../sass/_widgets.scss */
.news-list {
  margin-bottom: 0;
  padding-left: 0;
}
/* line 205, ../sass/_widgets.scss */
.news-list li {
  list-style: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-top: 1px solid #eeeeee;
  padding: 12px;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
/* line 213, ../sass/_widgets.scss */
.news-list li:hover {
  background: #f6f6f6;
}
/* line 217, ../sass/_widgets.scss */
.news-list li:last-child {
  margin-bottom: -10px;
}
/* line 222, ../sass/_widgets.scss */
.news-list img,
.news-list .icon {
  float: left;
  height: 50px;
  width: 50px;
}
/* line 229, ../sass/_widgets.scss */
.news-list .icon {
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 28px;
}
/* line 236, ../sass/_widgets.scss */
.news-list .news-item-info {
  margin-left: 62px;
  /*50 + 12px padding*/
}
/* line 240, ../sass/_widgets.scss */
.news-list .name {
  text-transform: uppercase;
}
/* line 243, ../sass/_widgets.scss */
.news-list .name a {
  text-decoration: none;
}
/* line 245, ../sass/_widgets.scss */
.news-list .name a:hover {
  color: #218BC3;
}

/***********************************/
/**      LIST GROUP SORTABLE      **/
/***********************************/
/* line 258, ../sass/_widgets.scss */
.list-group-sortable > .list-group-item {
  margin-bottom: 0;
  border-radius: 4px;
}
/* line 262, ../sass/_widgets.scss */
.list-group-sortable > .list-group-item + .list-group-item {
  margin-top: 10px;
}
/* line 267, ../sass/_widgets.scss */
.list-group-sortable > .list-group-item-placeholder {
  border: 1px dashed #999999;
  background-color: #ddd;
}
/* line 272, ../sass/_widgets.scss */
.list-group-sortable:last-of-type > .list-group-item:last-child {
  border-bottom: 1px solid #ddd;
}

/***********************************/
/**           ICON LIST           **/
/***********************************/
/* line 281, ../sass/_widgets.scss */
.icon-list {
  margin-top: 20px;
}

/* line 285, ../sass/_widgets.scss */
.icon-list-item {
  height: 32px;
  font-size: 14px;
  line-height: 32px;
}
/* line 289, ../sass/_widgets.scss */
.icon-list-item > a {
  color: #555555;
  text-decoration: none;
}
/* line 295, ../sass/_widgets.scss */
.icon-list-item .glyphicon,
.icon-list-item .fas,
.icon-list-item .far {
  width: 32px;
  margin-right: 10px;
}
/* line 302, ../sass/_widgets.scss */
.icon-list-item:hover .glyphicon,
.icon-list-item:hover .fas,
.icon-list-item:hover .far {
  font-size: 28px;
}
/* line 307, ../sass/_widgets.scss */
.icon-list-item:hover .fas,
.icon-list-item:hover .far {
  vertical-align: -5px;
}
/* line 311, ../sass/_widgets.scss */
.icon-list-item:hover .glyphicon {
  vertical-align: -6px;
}

/***********************************/
/**            INVOICE            **/
/***********************************/
/* line 321, ../sass/_widgets.scss */
.widget-invoice {
  padding: 40px;
}
/* line 325, ../sass/_widgets.scss */
.widget-invoice > header .invoice-logo {
  max-height: 50px;
}
/* line 329, ../sass/_widgets.scss */
.widget-invoice > header + .widget-body {
  margin-top: 70px;
}

/***********************************/
/**             LOGIN             **/
/***********************************/
/* line 339, ../sass/_widgets.scss */
.login-page {
  background-color: #ddd;
}

/* line 343, ../sass/_widgets.scss */
.login-page .page-footer {
  margin-bottom: 30px;
  font-size: 13px;
  color: #999999;
  text-align: center;
}
@media (min-height: 600px) {
  /* line 343, ../sass/_widgets.scss */
  .login-page .page-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
  }
}

/* line 356, ../sass/_widgets.scss */
.widget-login-container {
  padding-top: 10%;
}

/* line 360, ../sass/_widgets.scss */
.widget-login-logo {
  margin-bottom: 15px;
  text-align: center;
  font-weight: 400;
}
/* line 364, ../sass/_widgets.scss */
.widget-login-logo .fa-circle {
  font-size: 13px;
  margin: 0 20px;
}

/* line 370, ../sass/_widgets.scss */
.widget-login {
  padding: 30px;
}
/* line 374, ../sass/_widgets.scss */
.widget-login > header h1, .widget-login > header h2, .widget-login > header h3, .widget-login > header h4, .widget-login > header h5, .widget-login > header h6 {
  font-weight: 400;
}

/* line 380, ../sass/_widgets.scss */
.widget-login-info {
  font-size: 13px;
  color: #888;
  margin-top: 1px;
  margin-bottom: 0;
}

/* line 388, ../sass/_widgets.scss */
.login-form .form-control {
  font-size: 13px;
  border: none;
  background-color: #eeeeee;
}
/* line 392, ../sass/_widgets.scss */
.login-form .form-control:focus {
  background-color: #ddd;
}

/***********************************/
/**        Full Calendar          **/
/***********************************/
/* line 402, ../sass/_widgets.scss */
.full-calendar {
  margin-top: 10px;
}

/* line 407, ../sass/_widgets.scss */
.calendar-controls .btn {
  font-size: 13px;
}

/* line 412, ../sass/_widgets.scss */
.calendar-external-events {
  margin-top: 20px;
}
/* line 415, ../sass/_widgets.scss */
.calendar-external-events .external-event {
  margin: 10px 0;
  padding: 6px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 4px;
}

@media (min-width: 1200px) {
  /* line 424, ../sass/_widgets.scss */
  .widget-calendar {
      margin-top: -100px;
  }
}

/***********************************/
/**          Post Links           **/
/***********************************/
/* line 434, ../sass/_widgets.scss */
.post-links {
  margin-bottom: 0;
  font-size: 12px;
  padding-left: 0;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-links:before, .post-links:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-links:after {
  clear: both;
}
/* line 439, ../sass/_widgets.scss */
.post-links > li {
  float: left;
  list-style: none;
}
/* line 444, ../sass/_widgets.scss */
.post-links > li + li:before {
  color: #999;
  content: "\25cf";
  padding: 0 8px;
}
/* line 450, ../sass/_widgets.scss */
.post-links > li > a {
  text-decoration: none;
  color: #999999;
}
/* line 453, ../sass/_widgets.scss */
.post-links > li > a:hover {
  color: #999999;
}
/* line 459, ../sass/_widgets.scss */
.post-links.no-separator > li + li {
  margin-left: 12px;
}
/* line 461, ../sass/_widgets.scss */
.post-links.no-separator > li + li:before {
  content: normal;
}

/***********************************/
/**          Post Comments           **/
/***********************************/
/* line 471, ../sass/_widgets.scss */
.post-comments {
  font-size: 12px;
  padding-left: 0;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-comments:before, .post-comments:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-comments:after {
  clear: both;
}
/* line 476, ../sass/_widgets.scss */
.post-links + .post-comments {
  margin-top: 10px;
}
/* line 480, ../sass/_widgets.scss */
.post-comments > li {
  padding: 10px;
  border-top: 1px solid #e7e7e7;
  list-style: none;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-comments > li:before, .post-comments > li:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-comments > li:after {
  clear: both;
}
/* line 486, ../sass/_widgets.scss */
.post-comments > li:last-child {
  padding-bottom: 0;
}
/* line 491, ../sass/_widgets.scss */
.post-comments p:last-child {
  margin-bottom: 0;
}
/* line 495, ../sass/_widgets.scss */
.post-comments .avatar {
  margin-top: 1px;
}
/* line 499, ../sass/_widgets.scss */
.post-comments .author {
  margin-top: 0;
  margin-bottom: 2px;
  color: #7ca9dd;
}
/* line 505, ../sass/_widgets.scss */
.post-comments .comment-body {
  overflow: auto;
}
/* line 509, ../sass/_widgets.scss */
.post-comments h6.author > small {
  font-size: 11px;
}
/* line 513, ../sass/_widgets.scss */
.widget > footer .post-comments {
  margin-left: -20px;
  margin-right: -20px;
}

/***********************************/
/**           Post User           **/
/***********************************/
/* line 523, ../sass/_widgets.scss */
.post-user {
  position: relative;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-user:before, .post-user:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.post-user:after {
  clear: both;
}
/* line 527, ../sass/_widgets.scss */
.post-user img {
  border: 3px solid white;
}

/***********************************/
/**            Gallery            **/
/***********************************/
/* line 540, ../sass/_widgets.scss */
.gallery .thumbnail a {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
}
/* line 544, ../sass/_widgets.scss */
.gallery .thumbnail a > img {
  -webkit-transition: -webkit-transform 0.15s ease;
  -moz-transition: -moz-transform 0.15s ease;
  -o-transition: -o-transform 0.15s ease;
  transition: transform 0.15s ease;
}
/* line 549, ../sass/_widgets.scss */
.gallery .thumbnail:hover a > img {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
/* line 555, ../sass/_widgets.scss */
.gallery .post-links > li > a {
  display: inline;
}
/* line 559, ../sass/_widgets.scss */
.gallery > div {
  max-width: 100%;
}

/***********************************/
/**         Search Result         **/
/***********************************/
/* line 569, ../sass/_widgets.scss */
.search-result-categories > li > a {
  color: #777;
  font-weight: 400;
}
/* line 572, ../sass/_widgets.scss */
.search-result-categories > li > a:hover {
  background-color: #ddd;
  color: #555555;
}
/* line 577, ../sass/_widgets.scss */
.search-result-categories > li > a > .glyphicon {
  margin-right: 5px;
}
/* line 581, ../sass/_widgets.scss */
.search-result-categories > li > a > .badge {
  float: right;
}

/* line 587, ../sass/_widgets.scss */
.search-results-count {
  margin-top: 10px;
}

/* line 591, ../sass/_widgets.scss */
.search-result-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.search-result-item:before, .search-result-item:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.search-result-item:after {
  clear: both;
}
/* line 597, ../sass/_widgets.scss */
.search-result-item .image-link {
  display: block;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
@media (min-width: 768px) {
  /* line 597, ../sass/_widgets.scss */
  .search-result-item .image-link {
      display: inline-block;
      margin: -20px 0 -20px -20px;
      float: left;
      width: 200px;
  }
}
@media (max-width: 767px) {
  /* line 597, ../sass/_widgets.scss */
  .search-result-item .image-link {
      max-height: 200px;
  }
}
/* line 613, ../sass/_widgets.scss */
.search-result-item .image {
  max-width: 100%;
}
/* line 617, ../sass/_widgets.scss */
.search-result-item .info {
  margin-top: 2px;
  font-size: 12px;
  color: #999999;
}
/* line 623, ../sass/_widgets.scss */
.search-result-item .description {
  font-size: 13px;
}
/* line 627, ../sass/_widgets.scss */
.search-result-item + .search-result-item {
  margin-top: 20px;
}

@media (min-width: 768px) {
  /* line 632, ../sass/_widgets.scss */
  .search-result-item-body {
      margin-left: 200px;
  }
}

/* line 638, ../sass/_widgets.scss */
.search-result-item-heading {
  font-weight: 400;
}
/* line 641, ../sass/_widgets.scss */
.search-result-item-heading > a {
  color: #555555;
}
@media (min-width: 768px) {
  /* line 638, ../sass/_widgets.scss */
  .search-result-item-heading {
      margin: 0;
  }
}

/***********************************/
/**           Time Line           **/
/***********************************/
/* line 654, ../sass/_widgets.scss */
.timeline {
  position: relative;
  min-height: 100%;
  list-style: none;
  padding-left: 0;
  margin-bottom: -40px;
  /* content padding bottom */
  padding-bottom: 80px;
  /* the time line :) */
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.timeline > li:before, .timeline > li:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.timeline > li:after {
  clear: both;
}
/* line 665, ../sass/_widgets.scss */
.timeline > li + li {
  margin-top: 30px;
}
/* line 671, ../sass/_widgets.scss */
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24%;
  width: 8px;
  content: " ";
  margin-left: -4px;
  background-color: #fff;
}
@media (min-width: 992px) {
  /* line 671, ../sass/_widgets.scss */
  .timeline:before {
      left: 50%;
      margin-left: -4px;
  }
}

/* line 691, ../sass/_widgets.scss */
.timeline .event {
  float: right;
  width: 68%;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 0;
  position: relative;
}
/* line 699, ../sass/_widgets.scss */
.timeline .event:before {
  right: 100%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border: 10px solid transparent;
  border-right-color: #fff;
  top: 15px;
}
/* line 710, ../sass/_widgets.scss */
.timeline .event > footer {
  margin: 20px -20px 0;
  padding: 10px 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #f3f3f3;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.timeline .event > footer:before, .timeline .event > footer:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.timeline .event > footer:after {
  clear: both;
}
/* line 719, ../sass/_widgets.scss */
.timeline .event > footer .thumb {
  margin-left: 10px;
}
/* line 724, ../sass/_widgets.scss */
.timeline .event .post-comments {
  margin-left: -20px;
  margin-right: -20px;
}
@media (min-width: 992px) {
  /* line 731, ../sass/_widgets.scss */
  .timeline .event {
      width: 45%;
  }
  /* line 735, ../sass/_widgets.scss */
  .timeline > li.on-left .event {
      float: left;
  }
  /* line 737, ../sass/_widgets.scss */
  .timeline > li.on-left .event:before {
      right: auto;
      left: 100%;
      border-right-color: transparent;
      border-left-color: #fff;
  }
}

/* line 748, ../sass/_widgets.scss */
.timeline .event-time {
  float: left;
  width: 18%;
  margin-top: 5px;
  text-align: right;
}
/* line 754, ../sass/_widgets.scss */
.timeline .event-time > .date {
  display: block;
  font-size: 15px;
}
/* line 759, ../sass/_widgets.scss */
.timeline .event-time > .time {
  display: block;
  font-size: 18px;
  font-weight: 400;
}
@media (min-width: 992px) {
  /* line 767, ../sass/_widgets.scss */
  .timeline .event-time {
      width: 46%;
  }
  /* line 771, ../sass/_widgets.scss */
  .timeline > li.on-left .event-time {
      float: right;
      text-align: left;
  }
}

/* line 780, ../sass/_widgets.scss */
.timeline .event-icon {
  position: absolute;
  left: 24%;
  width: 50px;
  height: 50px;
  line-height: 37px;
  margin-left: -25px;
  background-color: #fff;
  border: 7px solid #fff;
  border-radius: 50%;
  text-align: center;
}
/* line 792, ../sass/_widgets.scss */
.timeline .event-icon.event-icon-danger {
  background-color: #dd5826;
  border-color: #e27045;
}
/* line 793, ../sass/_widgets.scss */
.timeline .event-icon.event-icon-warning {
  background-color: #f0b518;
  border-color: #f2c03a;
}
/* line 794, ../sass/_widgets.scss */
.timeline .event-icon.event-icon-success {
  background-color: #64bd63;
  border-color: #7dc87c;
}
/* line 795, ../sass/_widgets.scss */
.timeline .event-icon.event-icon-info {
  background-color: #5dc4bf;
  border-color: #77cec9;
}
/* line 796, ../sass/_widgets.scss */
.timeline .event-icon.event-icon-primary {
  background-color: #5d8fc2;
  border-color: #77a1cc;
}
/* line 798, ../sass/_widgets.scss */
.timeline .event-icon.event-icon-danger, .timeline .event-icon.event-icon-warning, .timeline .event-icon.event-icon-success, .timeline .event-icon.event-icon-info, .timeline .event-icon.event-icon-primary {
  color: #fff;
}
@media (min-width: 992px) {
  /* line 780, ../sass/_widgets.scss */
  .timeline .event-icon {
      left: 50%;
  }
}
/* line 810, ../sass/_widgets.scss */
.timeline .event-icon > img {
  width: 36px;
  height: 36px;
  margin-top: -4px;
}

/* line 818, ../sass/_widgets.scss */
.event-heading {
  margin: 0 0 2px;
  font-weight: 600;
}
/* line 821, ../sass/_widgets.scss */
.event-heading > a {
  text-decoration: none;
  color: #7ca9dd;
}
/* line 825, ../sass/_widgets.scss */
.event-heading > small {
  font-weight: 600;
}
/* line 827, ../sass/_widgets.scss */
.event-heading > small > a {
  text-decoration: none;
  color: #999999;
}

/* line 834, ../sass/_widgets.scss */
.event-map {
  height: 200px;
  margin: 0 -20px -20px;
  overflow: visible !important;
}

/* line 840, ../sass/_widgets.scss */
.event-image {
  margin: 0 -20px -20px;
  max-height: 260px;
  overflow: hidden;
}
/* line 844, ../sass/_widgets.scss */
.event-image > img {
  max-width: 100%;
}

/***********************************/
/**          ERROR PAGE           **/
/***********************************/
/* line 853, ../sass/_widgets.scss */
.error-page {
  background-color: #ddd;
}

/* line 857, ../sass/_widgets.scss */
.error-container {
  padding-top: 5%;
  text-align: center;
}
/* line 861, ../sass/_widgets.scss */
.error-container > .btn {
  padding-left: 35px;
  padding-right: 35px;
}

/* line 867, ../sass/_widgets.scss */
.error-code {
  font-size: 80px;
  font-weight: 400;
  color: #343434;
}
@media (min-width: 768px) {
  /* line 867, ../sass/_widgets.scss */
  .error-code {
      font-size: 180px;
  }
}

/* line 876, ../sass/_widgets.scss */
.error-info {
  font-size: 20px;
  color: #343434;
}

/* line 881, ../sass/_widgets.scss */
.error-help {
  font-size: 14px;
}

/* line 885, ../sass/_widgets.scss */
.error-page .page-footer {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 13px;
  color: #999999;
  text-align: center;
}

/***********************************/
/**             EMAIL             **/
/***********************************/
/* line 902, ../sass/_widgets.scss */
.nav-email-folders > li > a {
  color: #777;
  font-weight: 400;
}
/* line 905, ../sass/_widgets.scss */
.nav-email-folders > li > a:hover {
  background-color: #e5e5e5;
  color: #555555;
}
/* line 910, ../sass/_widgets.scss */
.nav-email-folders > li > a > .glyphicon {
  margin-right: 5px;
}
/* line 914, ../sass/_widgets.scss */
.nav-email-folders > li > a > .fa-circle {
  margin-top: 3px;
}
/* line 918, ../sass/_widgets.scss */
.nav-email-folders > li > a > .badge {
  background-color: #dd5826;
}
/* line 924, ../sass/_widgets.scss */
.nav-email-folders > li.active > a, .nav-email-folders > li.active > a:hover, .nav-email-folders > li.active > a:focus {
  background-color: #ddd;
  color: #555555;
  font-weight: 600;
}
/* line 931, ../sass/_widgets.scss */
.nav-email-folders > li.active > a > .badge, .nav-email-folders > li.active > a:hover > .badge, .nav-email-folders > li.active > a:focus > .badge {
  color: #555555;
}

/* line 938, ../sass/_widgets.scss */
.widget-email-count {
  display: inline-block;
  margin: 0;
  font-size: 13px;
  color: #999999;
  line-height: 29px;
}
/* line 945, ../sass/_widgets.scss */
.widget-email-count + .widget-email-pagination {
  margin-left: 10px;
  border-left: 1px solid #ddd;
  padding-left: 15px;
  border-radius: 0;
  vertical-align: -9px;
}

/* line 954, ../sass/_widgets.scss */
.widget-email-pagination {
  margin: 0;
}

/* line 958, ../sass/_widgets.scss */
.table-emails {
  margin-bottom: 0;
}
/* line 961, ../sass/_widgets.scss */
.table-emails td > .checkbox,
.table-emails th > .checkbox {
  top: -9px;
}
/* line 966, ../sass/_widgets.scss */
.table-emails .name,
.table-emails .subject,
.table-emails .date {
  cursor: pointer;
}
/* line 972, ../sass/_widgets.scss */
.table-emails .date {
  text-align: right;
  min-width: 65px;
}
/* line 977, ../sass/_widgets.scss */
.table-emails .unread {
  font-weight: 600;
  color: #555555;
}
/* line 982, ../sass/_widgets.scss */
.table-emails .starred {
  color: #999999;
  cursor: pointer;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}
/* line 986, ../sass/_widgets.scss */
.table-emails .starred:hover {
  color: #555555;
}
/* line 990, ../sass/_widgets.scss */
.table-emails .starred .fa-star {
  color: #f0b518;
}

/* line 997, ../sass/_widgets.scss */
.email-view hr {
  margin: 5px 0;
}
/* line 1001, ../sass/_widgets.scss */
.email-view .email-body {
  margin-top: 20px;
}

/* line 1006, ../sass/_widgets.scss */
.email-details img {
  width: 30px;
  height: 30px;
  float: left;
}

/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.email-details-content:before, .email-details-content:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.email-details-content:after {
  clear: both;
}
/* line 1015, ../sass/_widgets.scss */
.email-details-content .email {
  color: #999999;
  font-size: 13px;
}
/* line 1020, ../sass/_widgets.scss */
.email-details-content .receiver {
  display: block;
  color: #999999;
  margin-top: -6px;
}
/* line 1026, ../sass/_widgets.scss */
.email-details-content .email-date {
  margin-right: 10px;
  line-height: 24px;
  vertical-align: middle;
}

/* line 1035, ../sass/_widgets.scss */
.email-attachments .attachment img {
  display: block;
}
/* line 1039, ../sass/_widgets.scss */
.email-attachments .attachment .title {
  margin: 0;
  font-weight: bold;
}

/***********************************/
/**           Weather             **/
/***********************************/
/* line 1052, ../sass/_widgets.scss */
.widget-image .forecast {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

/***********************************/
/**             Tags              **/
/***********************************/
/* line 1067, ../sass/_widgets.scss */
.tags {
  padding-left: 0;
  list-style: none;
}
/* line 14, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.tags:before, .tags:after {
  content: " ";
  display: table;
}
/* line 19, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.tags:after {
  clear: both;
}
/* line 1072, ../sass/_widgets.scss */
.tags > li {
  float: left;
}
/* line 1075, ../sass/_widgets.scss */
.tags > li > a {
  padding: 2px 8px;
  font-size: 13px;
  border-radius: 6px;
  border: 1px solid white;
  color: inherit;
  text-decoration: none;
}
/* line 1084, ../sass/_widgets.scss */
.tags > li > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
/* line 1089, ../sass/_widgets.scss */
.tags > li > a .fas,
.tags > li > a .far {
  font-size: 8px;
  vertical-align: 2px;
  margin-right: 3px;
  opacity: .8;
}
/* line 1097, ../sass/_widgets.scss */
.tags > li + li > a {
  margin-left: 6px;
}
/* line 1102, ../sass/_widgets.scss */
.widget-top-overflow > img + .tags {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
}

/***********************************/
/**       Chat List Group         **/
/***********************************/
/* line 1114, ../sass/_widgets.scss */
.widget-chat-list-group {
  padding-top: 10px;
}
/* line 1116, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item {
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  border: 0;
}
/* line 1122, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item + .list-group-item {
  margin-top: 30px;
}
/* line 1126, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item .thumb,
.widget-chat-list-group .list-group-item .thumb-sm {
  float: left;
  margin-right: 15px;
}
/* line 1132, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item .time {
  float: right;
  font-size: 12px;
  color: #218BC3;
}
/* line 1138, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item .sender {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 400;
}
/* line 1145, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item .body {
  font-size: 13px;
  color: #999999;
}
/* line 1151, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item.on-right .thumb,
.widget-chat-list-group .list-group-item.on-right .thumb-sm {
  float: right;
  margin-left: 15px;
  margin-right: 0;
}
/* line 1158, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item.on-right .time {
  float: left;
}
/* line 1162, ../sass/_widgets.scss */
.widget-chat-list-group .list-group-item.on-right .sender {
  text-align: right;
}