@import 'globals/variables';

.errorMessage {
  color: #b94a48;
}

.a4 {
  box-sizing: content-box;
  height: $a4-height;
  width: $a4-width;

  &.border {
    border: 1px solid #000000;
  }

  &.center {
    margin: 0 auto;
  }
}

.border {
  border: 1px solid #000000;
}

.content {
    h1, h2, h3, h4, h5, h6, 
    .h1, .h2, .h3, .h4, .h5, .h6 {
        font-weight: 300;
    }
}

.float-right {
  float: right;
}

.testimonials-list {
  margin-top: 10px;

  h2 {
    border-top: 10px solid #eeeeee;
    padding-top: 10px;
  }

  .preview {
    cursor: pointer;

    & .a4 {
      height: $a4-height * 0.15;
      min-height: 168px;
      transform: scale(0.15);
      transform-origin: 0 0;
      width: $a4-width * 0.15
    }

    & .caption > .checkbox > label {
      min-height: 40px;
    }
  }

  .order-buttons {
    text-align: center;
  }
}

.tire_recommendations, .dealer_background {
  .preview {
    cursor: pointer;
    min-height: 350px;

    & .a4 {
      height: $a4-height * 0.15;
      min-height: 168px;
      transform: scale(0.15);
      transform-origin: 0 0;
      width: $a4-width * 0.15
    }
  }
}

.page__row {
  margin-bottom: 5px;
}

.button-column {
    display: none;
}

.button-column2 {
  text-align: right;
  width: 164px;
  display: flex;

  & > .btn {
    margin-right: 10px;
  }
}

.contact-search > tbody > tr > td {
  vertical-align: middle;
}

#saving_loader {
  display: none;
  overflow: hidden;
  padding: 10px;
  text-align: center;
}

.row-widget {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  section {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .widget-body {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    video {
      cursor: pointer; 
      width: 100%;
    }

    .btn {
      margin-top: auto;
      white-space:normal;
    }
  }

  .widget-body__image {
    transition: transform 0.5s ease-in-out;
    :hover {
      transform: scale(1.005);
    }
    img {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    }
  }

  .widget-body__text {
    margin: 16px 0;
  }

  .widget__video {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 20%;

    .widget-body {
      max-width: 100%;
    }
    .widget {
      padding: 0;
      overflow: hidden;
    }
  
    @media (min-width: 768px) {
      height: 100%;
    }
  }

  .widget__table {
    overflow-x: hidden;
  }

  .row-widget--item {
    display: flex;
  }
}

iframe {
  border:none;
  width:100%
}

.tire-countries-checkbox,
.service-countries-checkbox,
.dealer-brands-checkbox {
  column-count: 2;

  &.checkbox {
    padding-left: 34px;
  } 
}

.tire-countries-input,
.service-countries-input,
.dealer-brands-input {
  width: 100%;
}

.tire-image-preview,
.service-image-preview {
  width: 200px;
}

.breadcrumb {
  background-color: transparent;
  padding-left: 0;
}

.note-editor .note-editing-area .note-editable {
    padding: 0;
    overflow: hidden;
}

.proposal-pages-menu {
  .btn-group-custom {
    width: 100%;
  }

  .btn {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 10pt;
  }

  .current_slide {
    color: #fff!important;
    background-color: #057855!important;
    border-color: #057855!important;
  }
}

.order-div{
  text-align:center;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto
}

.tire_size_container {
  margin-top: 12px;
}

.tire_size_table {
  th {
    &.loadIndex {
      width: 80px !important;
    }
    &.speedIndex {
      width: 60px !important;
    }
  }
}

@import 'pager.scss';
@import 'app.base.scss';
@import 'app.general.scss';
@import 'app.global-transitions.scss';
@import 'app.print.scss';
@import 'app.utils.scss';
@import 'app.widgets.scss';
