/***********************************/
/**            UTILS              **/
/***********************************/
/* line 8, ../sass/_utils.scss */
.transition-height {
  -webkit-transition: height 0.15s ease-in-out;
  -o-transition: height 0.15s ease-in-out;
  transition: height 0.15s ease-in-out;
}

/**
* .circle & .rounded are pretty cool way to add a bit of beauty to raw data.
* should be used with bg-* and text-* classes for colors.
*/
/* line 16, ../sass/_utils.scss */
.circle, .rounded, .circle-o {
  display: inline-block;
  width: 1.428571429em;
  height: 1.428571429em;
  line-height: 1.428571429em;
  text-align: center;
}

/* line 24, ../sass/_utils.scss */
.circle, .circle-o {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* line 28, ../sass/_utils.scss */
.rounded {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

/* line 32, ../sass/_utils.scss */
.circle-lg, .rounded-lg {
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
}

/* line 38, ../sass/_utils.scss */
.circle-o {
  border: 0.2857142858em solid transparent;
}
/* line 41, ../sass/_utils.scss */
.circle-o.circle-o-danger {
  border-color: #dd5826;
}
/* line 42, ../sass/_utils.scss */
.circle-o.circle-o-warning {
  border-color: #f0b518;
}
/* line 43, ../sass/_utils.scss */
.circle-o.circle-o-success {
  border-color: #64bd63;
}
/* line 44, ../sass/_utils.scss */
.circle-o.circle-o-info {
  border-color: #5dc4bf;
}
/* line 45, ../sass/_utils.scss */
.circle-o.circle-o-primary {
  border-color: #5d8fc2;
}
/* line 46, ../sass/_utils.scss */
.circle-o.circle-o-black {
  border-color: #000;
}
/* line 47, ../sass/_utils.scss */
.circle-o.circle-o-gray-darker {
  border-color: #222222;
}
/* line 48, ../sass/_utils.scss */
.circle-o.circle-o-gray-dark {
  border-color: #343434;
}
/* line 49, ../sass/_utils.scss */
.circle-o.circle-o-gray {
  border-color: #555555;
}
/* line 50, ../sass/_utils.scss */
.circle-o.circle-o-gray-light {
  border-color: #999999;
}
/* line 51, ../sass/_utils.scss */
.circle-o.circle-o-gray-lighter {
  border-color: #eeeeee;
}
/* line 52, ../sass/_utils.scss */
.circle-o.circle-o-white {
  border-color: #fff;
}

/* line 16, ../sass/_mixins.scss */
.thumb-lg {
  width: 60px;
}
/* line 19, ../sass/_mixins.scss */
.thumb-lg > .glyphicon,
.thumb-lg > .fas,
.thumb-lg > .far {
  line-height: 60px;
}

/* line 16, ../sass/_mixins.scss */
.thumb {
  width: 48px;
}
/* line 19, ../sass/_mixins.scss */
.thumb > .glyphicon,
.thumb > .fas,
.thumb > .far {
  line-height: 48px;
}

/* line 16, ../sass/_mixins.scss */
.thumb-sm {
  width: 34px;
}
/* line 19, ../sass/_mixins.scss */
.thumb-sm > .glyphicon,
.thumb-sm > .fas {
  line-height: 34px;
}

/* line 16, ../sass/_mixins.scss */
.thumb-xs {
  width: 28px;
}
/* line 19, ../sass/_mixins.scss */
.thumb-xs > .glyphicon,
.thumb-xs > .fas,
.thumb-xs > .far {
  line-height: 28px;
}

/* line 60, ../sass/_utils.scss */
.thumb-xs,
.thumb-sm,
.thumb-lg,
.thumb {
  position: relative;
  display: inline-block;
  text-align: center;
}
/* line 68, ../sass/_utils.scss */
.thumb-xs img,
.thumb-sm img,
.thumb-lg img,
.thumb img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
/* line 74, ../sass/_utils.scss */
.thumb-xs .status,
.thumb-sm .status,
.thumb-lg .status,
.thumb .status {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border: 0.15em solid #fff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
/* line 84, ../sass/_utils.scss */
.thumb-xs .status.status-bottom,
.thumb-sm .status.status-bottom,
.thumb-lg .status.status-bottom,
.thumb .status.status-bottom {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}

/* line 93, ../sass/_utils.scss */
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* line 100, ../sass/_utils.scss */
.input-dark {
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  color: #555555;
}
/* line 104, ../sass/_utils.scss */
.input-dark:focus {
  background-color: #eeeeee;
}

/* line 110, ../sass/_utils.scss */
.input-group-dark .input-group-addon,
.input-group-dark .form-control {
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  color: #555555;
  -webkit-transition: background-color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
}
/* line 118, ../sass/_utils.scss */
.input-group-dark .form-control:focus,
.input-group-dark .form-control:focus + .input-group-addon, .input-group-dark.focus .input-group-addon {
  background-color: #eeeeee;
}

/* line 126, ../sass/_utils.scss */
.input-group-transparent .form-control:first-child, .input-group-no-border .form-control:first-child,
.input-group-transparent .input-group-addon:first-child,
.input-group-no-border .input-group-addon:first-child,
.input-group-transparent .input-group-btn:first-child > .btn,
.input-group-no-border .input-group-btn:first-child > .btn,
.input-group-transparent .input-group-btn:first-child > .btn-group > .btn,
.input-group-no-border .input-group-btn:first-child > .btn-group > .btn,
.input-group-transparent .input-group-btn:first-child > .dropdown-toggle,
.input-group-no-border .input-group-btn:first-child > .dropdown-toggle,
.input-group-transparent .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-no-border .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-transparent .input-group-btn:last-child > .btn-group:not(:last-child) > .btn,
.input-group-no-border .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
/* line 138, ../sass/_utils.scss */
.input-group-transparent .form-control:last-child, .input-group-no-border .form-control:last-child,
.input-group-transparent .input-group-addon:last-child,
.input-group-no-border .input-group-addon:last-child,
.input-group-transparent .input-group-btn:last-child > .btn,
.input-group-no-border .input-group-btn:last-child > .btn,
.input-group-transparent .input-group-btn:last-child > .btn-group > .btn,
.input-group-no-border .input-group-btn:last-child > .btn-group > .btn,
.input-group-transparent .input-group-btn:last-child > .dropdown-toggle,
.input-group-no-border .input-group-btn:last-child > .dropdown-toggle,
.input-group-transparent .input-group-btn:first-child > .btn:not(:first-child),
.input-group-no-border .input-group-btn:first-child > .btn:not(:first-child),
.input-group-transparent .input-group-btn:first-child > .btn-group:not(:first-child) > .btn,
.input-group-no-border .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  padding-left: 0;
}
/* line 151, ../sass/_utils.scss */
.input-group-transparent .input-group-addon, .input-group-no-border .input-group-addon {
  background-color: #fff;
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}
/* line 156, ../sass/_utils.scss */
.input-group-transparent .form-control:focus + .input-group-addon, .input-group-no-border .form-control:focus + .input-group-addon, .input-group-transparent.focus .input-group-addon, .focus.input-group-no-border .input-group-addon {
  border-color: #4D90FE;
}

/* line 162, ../sass/_utils.scss */
.input-no-border, .input-group-no-border .form-control,
.input-group-no-border .input-group-addon {
  border: none;
  -webkit-transition: background-color ease-in-out 0.15s;
  -o-transition: background-color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s;
}
/* line 166, ../sass/_utils.scss */
.input-no-border:focus, .input-group-no-border .form-control:focus,
.input-group-no-border .input-group-addon:focus,
.input-group-no-border .form-control:focus + .input-group-addon, .input-group-no-border.focus .input-group-addon {
  background-color: #f1f1f1;
}

/* line 187, ../sass/_utils.scss */
.input-group-rounded .form-control:first-child,
.input-group-rounded .input-group-addon:first-child,
.input-group-rounded .input-group-btn:first-child > .btn,
.input-group-rounded .input-group-btn:first-child > .btn-group > .btn,
.input-group-rounded .input-group-btn:first-child > .dropdown-toggle,
.input-group-rounded .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-rounded .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/* line 198, ../sass/_utils.scss */
.input-group-rounded .form-control:last-child,
.input-group-rounded .input-group-addon:last-child,
.input-group-rounded .input-group-btn:last-child > .btn,
.input-group-rounded .input-group-btn:last-child > .btn-group > .btn,
.input-group-rounded .input-group-btn:last-child > .dropdown-toggle,
.input-group-rounded .input-group-btn:first-child > .btn:not(:first-child),
.input-group-rounded .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* line 210, ../sass/_utils.scss */
.chart-overflow-bottom {
  margin: 0 -20px -15px;
}

/* line 214, ../sass/_utils.scss */
.globe {
  font-size: 64px;
}
/* line 216, ../sass/_utils.scss */
.globe > i {
  position: relative;
}
/* line 218, ../sass/_utils.scss */
.globe > i > i {
  position: absolute;
  left: 0;
}

/* line 226, ../sass/_utils.scss */
.thin-scroll::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
/* line 230, ../sass/_utils.scss */
.thin-scroll::-webkit-scrollbar:hover {
  background-color: #e9e9e9;
  border: none;
}
/* line 234, ../sass/_utils.scss */
.thin-scroll::-webkit-scrollbar-thumb {
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
/* line 239, ../sass/_utils.scss */
.thin-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}

@-webkit-keyframes bg-attention {
  0% {
      background-color: #fdf7e6;
  }
  5% {
      background-color: #fae8b6;
  }
  0% {
      background-color: transparent;
  }
}
@keyframes bg-attention {
  0% {
      background-color: #fdf7e6;
  }
  5% {
      background-color: #fae8b6;
  }
  0% {
      background-color: transparent;
  }
}
/* line 256, ../sass/_utils.scss */
.bg-attention {
  -webkit-animation: bg-attention 10s;
  -o-animation: bg-attention 10s;
  animation: bg-attention 10s;
}

/* .h* + .fw-bold */
/* line 265, ../sass/_utils.scss */
.value0 {
  margin: 0;
  font-size: 54px;
  font-weight: 600;
}

/* line 265, ../sass/_utils.scss */
.value1 {
  margin: 0;
  font-size: 36px;
  font-weight: 600;
}

/* line 265, ../sass/_utils.scss */
.value2 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

/* line 265, ../sass/_utils.scss */
.value3 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

/* line 265, ../sass/_utils.scss */
.value4 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

/* line 265, ../sass/_utils.scss */
.value5 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

/* line 265, ../sass/_utils.scss */
.value6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

/* line 273, ../sass/_utils.scss */
.help {
  font-size: 85%;
  color: #999999;
}

/* line 281, ../sass/_utils.scss */
.text-align-center {
  text-align: center;
}

/* line 285, ../sass/_utils.scss */
.text-align-right {
  text-align: right;
}

/* line 289, ../sass/_utils.scss */
.no-margin {
  margin: 0;
}

/* line 39, ../sass/_mixins.scss */
.mt-xlg {
  margin-top: 40px;
}

/* line 42, ../sass/_mixins.scss */
.mt-n-xlg {
  margin-top: -40px;
}

/* line 39, ../sass/_mixins.scss */
.mt-lg {
  margin-top: 30px;
}

/* line 42, ../sass/_mixins.scss */
.mt-n-lg {
  margin-top: -30px;
}

/* line 32, ../sass/_mixins.scss */
.mt {
  margin-top: 20px;
}

/* line 35, ../sass/_mixins.scss */
.mt-n {
  margin-top: 20px;
}

/* line 39, ../sass/_mixins.scss */
.mt-md {
  margin-top: 20px;
}

/* line 42, ../sass/_mixins.scss */
.mt-n-md {
  margin-top: -20px;
}

/* line 39, ../sass/_mixins.scss */
.mt-sm {
  margin-top: 10px;
}

/* line 42, ../sass/_mixins.scss */
.mt-n-sm {
  margin-top: -10px;
}

/* line 39, ../sass/_mixins.scss */
.mt-xs {
  margin-top: 5px;
}

/* line 42, ../sass/_mixins.scss */
.mt-n-xs {
  margin-top: -5px;
}

/* line 39, ../sass/_mixins.scss */
.mt-0 {
  margin-top: 0;
}

/* line 42, ../sass/_mixins.scss */
.mt-n-0 {
  margin-top: 0;
}

/* line 39, ../sass/_mixins.scss */
.mb-xlg {
  margin-bottom: 40px;
}

/* line 42, ../sass/_mixins.scss */
.mb-n-xlg {
  margin-bottom: -40px;
}

/* line 39, ../sass/_mixins.scss */
.mb-lg {
  margin-bottom: 30px;
}

/* line 42, ../sass/_mixins.scss */
.mb-n-lg {
  margin-bottom: -30px;
}

/* line 32, ../sass/_mixins.scss */
.mb {
  margin-bottom: 20px;
}

/* line 35, ../sass/_mixins.scss */
.mb-n {
  margin-bottom: 20px;
}

/* line 39, ../sass/_mixins.scss */
.mb-md {
  margin-bottom: 20px;
}

/* line 42, ../sass/_mixins.scss */
.mb-n-md {
  margin-bottom: -20px;
}

/* line 39, ../sass/_mixins.scss */
.mb-sm {
  margin-bottom: 10px;
}

/* line 42, ../sass/_mixins.scss */
.mb-n-sm {
  margin-bottom: -10px;
}

/* line 39, ../sass/_mixins.scss */
.mb-xs {
  margin-bottom: 5px;
}

/* line 42, ../sass/_mixins.scss */
.mb-n-xs {
  margin-bottom: -5px;
}

/* line 39, ../sass/_mixins.scss */
.mb-0 {
  margin-bottom: 0;
}

/* line 42, ../sass/_mixins.scss */
.mb-n-0 {
  margin-bottom: 0;
}

/* line 39, ../sass/_mixins.scss */
.ml-lg {
  margin-left: 20px;
}

/* line 42, ../sass/_mixins.scss */
.ml-n-lg {
  margin-left: -20px;
}

/* line 32, ../sass/_mixins.scss */
.ml {
  margin-left: 15px;
}

/* line 35, ../sass/_mixins.scss */
.ml-n {
  margin-left: 15px;
}

/* line 39, ../sass/_mixins.scss */
.ml-md {
  margin-left: 15px;
}

/* line 42, ../sass/_mixins.scss */
.ml-n-md {
  margin-left: -15px;
}

/* line 39, ../sass/_mixins.scss */
.ml-sm {
  margin-left: 10px;
}

/* line 42, ../sass/_mixins.scss */
.ml-n-sm {
  margin-left: -10px;
}

/* line 39, ../sass/_mixins.scss */
.ml-xs {
  margin-left: 5px;
}

/* line 42, ../sass/_mixins.scss */
.ml-n-xs {
  margin-left: -5px;
}

/* line 39, ../sass/_mixins.scss */
.ml-0 {
  margin-left: 0;
}

/* line 42, ../sass/_mixins.scss */
.ml-n-0 {
  margin-left: 0;
}

/* line 39, ../sass/_mixins.scss */
.mr-lg {
  margin-right: 20px;
}

/* line 42, ../sass/_mixins.scss */
.mr-n-lg {
  margin-right: -20px;
}

/* line 32, ../sass/_mixins.scss */
.mr {
  margin-right: 15px;
}

/* line 35, ../sass/_mixins.scss */
.mr-n {
  margin-right: 15px;
}

/* line 39, ../sass/_mixins.scss */
.mr-md {
  margin-right: 15px;
}

/* line 42, ../sass/_mixins.scss */
.mr-n-md {
  margin-right: -15px;
}

/* line 39, ../sass/_mixins.scss */
.mr-sm {
  margin-right: 10px;
}

/* line 42, ../sass/_mixins.scss */
.mr-n-sm {
  margin-right: -10px;
}

/* line 39, ../sass/_mixins.scss */
.mr-xs {
  margin-right: 5px;
}

/* line 42, ../sass/_mixins.scss */
.mr-n-xs {
  margin-right: -5px;
}

/* line 39, ../sass/_mixins.scss */
.mr-0 {
  margin-right: 0;
}

/* line 42, ../sass/_mixins.scss */
.mr-n-0 {
  margin-right: 0;
}

/* line 301, ../sass/_utils.scss */
.no-padding {
  padding: 0;
}

/* line 39, ../sass/_mixins.scss */
.display-inline {
  display: inline;
}

/* line 42, ../sass/_mixins.scss */
.display-n-inline {
  display: -inline;
}

/* line 39, ../sass/_mixins.scss */
.display-inline-block {
  display: inline-block;
}

/* line 42, ../sass/_mixins.scss */
.display-n-inline-block {
  display: -inline-block;
}

/* line 39, ../sass/_mixins.scss */
.display-block {
  display: block;
}

/* line 42, ../sass/_mixins.scss */
.display-n-block {
  display: -block;
}

/* line 39, ../sass/_mixins.scss */
.width-50 {
  width: 50px;
}

/* line 42, ../sass/_mixins.scss */
.width-n-50 {
  width: -50px;
}

/* line 39, ../sass/_mixins.scss */
.width-100 {
  width: 100px;
}

/* line 42, ../sass/_mixins.scss */
.width-n-100 {
  width: -100px;
}

/* line 39, ../sass/_mixins.scss */
.width-150 {
  width: 150px;
}

/* line 42, ../sass/_mixins.scss */
.width-n-150 {
  width: -150px;
}

/* line 39, ../sass/_mixins.scss */
.width-200 {
  width: 200px;
}

/* line 42, ../sass/_mixins.scss */
.width-n-200 {
  width: -200px;
}

/* line 39, ../sass/_mixins.scss */
.height-50 {
  height: 50px;
}

/* line 42, ../sass/_mixins.scss */
.height-n-50 {
  height: -50px;
}

/* line 39, ../sass/_mixins.scss */
.height-100 {
  height: 100px;
}

/* line 42, ../sass/_mixins.scss */
.height-n-100 {
  height: -100px;
}

/* line 39, ../sass/_mixins.scss */
.height-150 {
  height: 150px;
}

/* line 42, ../sass/_mixins.scss */
.height-n-150 {
  height: -150px;
}

/* line 39, ../sass/_mixins.scss */
.height-200 {
  height: 200px;
}

/* line 42, ../sass/_mixins.scss */
.height-n-200 {
  height: -200px;
}

/* line 39, ../sass/_mixins.scss */
.height-250 {
  height: 250px;
}

/* line 42, ../sass/_mixins.scss */
.height-n-250 {
  height: -250px;
}

/* line 39, ../sass/_mixins.scss */
.max-height-50 {
  max-height: 50px;
}

/* line 42, ../sass/_mixins.scss */
.max-height-n-50 {
  max-height: -50px;
}

/* line 39, ../sass/_mixins.scss */
.max-height-100 {
  max-height: 100px;
}

/* line 42, ../sass/_mixins.scss */
.max-height-n-100 {
  max-height: -100px;
}

/* line 39, ../sass/_mixins.scss */
.max-height-150 {
  max-height: 150px;
}

/* line 42, ../sass/_mixins.scss */
.max-height-n-150 {
  max-height: -150px;
}

/* line 39, ../sass/_mixins.scss */
.max-height-200 {
  max-height: 200px;
}

/* line 42, ../sass/_mixins.scss */
.max-height-n-200 {
  max-height: -200px;
}

/* line 39, ../sass/_mixins.scss */
.max-height-250 {
  max-height: 250px;
}

/* line 42, ../sass/_mixins.scss */
.max-height-n-250 {
  max-height: -250px;
}

/* line 39, ../sass/_mixins.scss */
.opacity-10 {
  opacity: 0.1;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-10 {
  opacity: -0.1;
}

/* line 39, ../sass/_mixins.scss */
.opacity-20 {
  opacity: 0.2;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-20 {
  opacity: -0.2;
}

/* line 39, ../sass/_mixins.scss */
.opacity-30 {
  opacity: 0.3;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-30 {
  opacity: -0.3;
}

/* line 39, ../sass/_mixins.scss */
.opacity-40 {
  opacity: 0.4;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-40 {
  opacity: -0.4;
}

/* line 39, ../sass/_mixins.scss */
.opacity-50 {
  opacity: 0.5;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-50 {
  opacity: -0.5;
}

/* line 39, ../sass/_mixins.scss */
.opacity-60 {
  opacity: 0.6;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-60 {
  opacity: -0.6;
}

/* line 39, ../sass/_mixins.scss */
.opacity-70 {
  opacity: 0.7;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-70 {
  opacity: -0.7;
}

/* line 39, ../sass/_mixins.scss */
.opacity-80 {
  opacity: 0.8;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-80 {
  opacity: -0.8;
}

/* line 39, ../sass/_mixins.scss */
.opacity-90 {
  opacity: 0.9;
}

/* line 42, ../sass/_mixins.scss */
.opacity-n-90 {
  opacity: -0.9;
}

/* line 39, ../sass/_mixins.scss */
.z-index-more {
  z-index: 1;
}

/* line 42, ../sass/_mixins.scss */
.z-index-n-more {
  z-index: -1;
}

/* line 39, ../sass/_mixins.scss */
.z-index-less {
  z-index: -1;
}

/* line 42, ../sass/_mixins.scss */
.z-index-n-less {
  z-index: 1;
}

/* line 327, ../sass/_utils.scss */
.no-border {
  border: none;
}

/* line 331, ../sass/_utils.scss */
.bt {
  border-top: 1px solid #ddd;
}

/* line 332, ../sass/_utils.scss */
.br {
  border-right: 1px solid #ddd;
}

/* line 333, ../sass/_utils.scss */
.bb {
  border-bottom: 1px solid #ddd;
}

/* line 334, ../sass/_utils.scss */
.bl {
  border-left: 1px solid #ddd;
}

/* line 336, ../sass/_utils.scss */
.overflow-hidden {
  overflow: hidden;
}

/* line 340, ../sass/_utils.scss */
.fw-semi-bold {
  font-weight: 600;
}

/* line 344, ../sass/_utils.scss */
.fw-normal {
  font-weight: 400;
}

/* line 348, ../sass/_utils.scss */
.fw-bold {
  font-weight: 700;
}

/* line 352, ../sass/_utils.scss */
.fw-thin {
  font-weight: 300;
}

/* line 356, ../sass/_utils.scss */
.rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* line 360, ../sass/_utils.scss */
.rotate-135 {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* line 364, ../sass/_utils.scss */
.rotate-225 {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* line 368, ../sass/_utils.scss */
.rotate-315 {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  transform: rotate(315deg);
}

/* small + .text-muted*/
/* line 373, ../sass/_utils.scss */
.deemphasize {
  font-size: 85%;
  color: #999999;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-primary {
  color: #5d8fc2;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-primary:hover {
  color: #4175ab;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-success {
  color: #64bd63;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-success:hover {
  color: #48a746;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-info {
  color: #5dc4bf;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-info:hover {
  color: #40aea9;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-warning {
  color: #f0b518;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-warning:hover {
  color: #c8950d;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-danger {
  color: #dd5826;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-danger:hover {
  color: #b4461c;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-link {
  color: #218BC3;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-link:hover {
  color: #1a6c97;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-primary {
  background-color: #5d8fc2;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-primary:hover {
  background-color: #4175ab;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-success {
  background-color: #64bd63;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-success:hover {
  background-color: #48a746;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-info {
  background-color: #5dc4bf;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-info:hover {
  background-color: #40aea9;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-warning {
  background-color: #f0b518;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-warning:hover {
  background-color: #c8950d;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-danger {
  background-color: #dd5826;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-danger:hover {
  background-color: #b4461c;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-primary-light {
  background-color: #dee4ee;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-primary-light:hover {
  background-color: #bcc8dd;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-success-light {
  background-color: #ecfaec;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-success-light:hover {
  background-color: #c4efc4;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-info-light {
  background-color: #f2fafa;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-info-light:hover {
  background-color: #cdecec;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-warning-light {
  background-color: #fdf7e6;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-warning-light:hover {
  background-color: #fae8b6;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-danger-light {
  background-color: #fff2ef;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-danger-light:hover {
  background-color: #ffc9bc;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-body {
  background-color: #eee;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-body:hover {
  background-color: #d4d4d4;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-body-light {
  background-color: #f6f6f6;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-body-light:hover {
  background-color: gainsboro;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-gray {
  background-color: #555555;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-gray:hover {
  background-color: #3c3c3c;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-gray-transparent {
  background-color: rgba(85, 85, 85, 0.6);
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-gray-transparent:hover {
  background-color: rgba(60, 60, 60, 0.6);
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-gray-dark {
  background-color: #343434;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-gray-dark:hover {
  background-color: #1a1a1a;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-black {
  background-color: #000;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-black:hover {
  background-color: black;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-gray-light {
  background-color: #999999;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-gray-light:hover {
  background-color: gray;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-gray-lighter {
  background-color: #eeeeee;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-gray-lighter:hover {
  background-color: #d5d5d5;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-transparent {
  background-color: transparent;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-transparent:hover {
  background-color: transparent;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-white {
  background-color: #fff;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-white:hover {
  background-color: #e6e6e6;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
.bg-sidebar {
  background-color: #242424;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_background-variant.scss */
a.bg-sidebar:hover {
  background-color: #0a0a0a;
}

/* line 422, ../sass/_utils.scss */
.bg-body, .bg-body-light, .bg-gray, .bg-gray-transparent, .bg-transparent,
.bg-primary, .bg-success, .bg-warning, .bg-danger, .bg-info {
  -webkit-transition: background-color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-white {
  color: #fff;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-white:hover {
  color: #e6e6e6;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-gray {
  color: #555555;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-gray:hover {
  color: #3c3c3c;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-gray-dark {
  color: #343434;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-gray-dark:hover {
  color: #1a1a1a;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-gray-light {
  color: #999999;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-gray-light:hover {
  color: gray;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-semi-muted {
  color: #777;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-semi-muted:hover {
  color: #5e5e5e;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-gray-darker {
  color: #222222;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-gray-darker:hover {
  color: #090909;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-gray-lighter {
  color: #eeeeee;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-gray-lighter:hover {
  color: #d5d5d5;
}

/* line 5, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
.text-bg-sidebar {
  color: #242424;
}

/* line 8, ../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins/_text-emphasis.scss */
a.text-bg-sidebar:hover {
  color: #0a0a0a;
}

/* line 437, ../sass/_utils.scss */
.text-light {
  opacity: .8;
}

/* line 441, ../sass/_utils.scss */
.fs-mini {
  font-size: 13px;
}

/* line 445, ../sass/_utils.scss */
.fs-sm {
  font-size: 12px;
}

/* line 449, ../sass/_utils.scss */
.fs-larger {
  font-size: 15px;
}

/* line 453, ../sass/_utils.scss */
.btlr {
  border-top-left-radius: 4px;
}

/* line 457, ../sass/_utils.scss */
.btrr {
  border-top-right-radius: 4px;
}

/* line 461, ../sass/_utils.scss */
.bblr {
  border-bottom-left-radius: 4px;
}

/* line 465, ../sass/_utils.scss */
.bbrr {
  border-bottom-right-radius: 4px;
}