/**
 * CSS styles for CLinkPager.
 *
 * @author Qiang Xue <qiang.xue@gmail.com>
 * @link https://www.yiiframework.com/
 * @copyright 2008-2010 Yii Software LLC
 * @license https://www.yiiframework.com/license/
 * @since 1.0
 */
@import 'globals/colors';

ul.yiiPager
{
    font-size:11px;
    border:0;
    margin:0;
    padding:0;
    line-height:100%;
    display:inline;
}

ul.yiiPager li
{
    display:inline;
}

ul.yiiPager a:link,
ul.yiiPager a:visited
{
    border:solid 1px #9aafe5;
    font-weight:bold;
    color:#0e509e;
    padding:1px 6px;
    text-decoration:none;
}

ul.yiiPager .page a
{
    font-weight:normal;
}

ul.yiiPager a:hover
{
    border:solid 1px #0e509e;
}

ul.yiiPager .selected a
{
    background:#2e6ab1;
    color:#FFFFFF;
    font-weight:bold;
}

ul.yiiPager .hidden a
{
    border:solid 1px #DEDEDE;
    color:#888888;
}

/**
 * Hide first and last buttons by default.
 */
ul.yiiPager .first,
ul.yiiPager .last
{
    display:none;
}





.pager ul.yiiPager .selected a,
.pager ul.yiiPager :not(.selected) a {
    border-color: $primary;
}
.pager ul.yiiPager .hidden {
    display: none;
}
.pager ul.yiiPager .page:not(.selected) a {
    color: $primary;
}
.pager ul.yiiPager li.next {
    color: $warning;
}
.pager ul.yiiPager .selected a {
    background-color: $primary;
}



