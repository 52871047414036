/* line 1, ../sass/_global-transitions.scss */
a {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

/* line 8, ../sass/_global-transitions.scss */
.btn {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

/* line 12, ../sass/_global-transitions.scss */
.form-control {
  -webkit-transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}